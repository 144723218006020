import styled from "styled-components"

export const Container = styled.div`
  margin-top:1.5rem;
`
export const Flex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top:10px ;
`
export const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`
export const Right = styled.div`
padding:1px;
`
export const Logo = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 50%;
`
export const Title = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #333 ;
`
export const Value = styled.div`
  font-size: 16px;
  font-weight: 550;
  color: #333;
`