import React from "react"
import * as Styled from "../styles"
import { performancesEmployes, topServices } from "../Utils"
import useSalonCurrency from "hooks/useSalonCurrency"
import { Tabulation } from "./tabulation"


export const TopServices = ({data}) => {
  const currency = useSalonCurrency()

  const tabs=[
          { key: "ventesDirectes", label: "Ventes Directes" },
          { key: "rendezVous", label: "Rendez-vous" },
        ] 

  return (
    <>
      {/* Section Top des Services */}
      <Tabulation
        title='Top des services'
        tabs={tabs}
        tabContents={{
          ventesDirectes: (
            <Styled.TopServices>
              {data?.topDirectSalesServices.map((topService) => (
                <Styled.FlexItem key={topService.id}>
                  <Styled.Label>{topService.name}</Styled.Label>
                  <Styled.LabelValue>
                    {topService.total} {currency}
                  </Styled.LabelValue>
                </Styled.FlexItem>
              ))}
            </Styled.TopServices>
          ),
          rendezVous: (
            <Styled.TopServices>
              {data?.topAppointmentsServices.map((topService) => (
                <Styled.FlexItem key={topService.id}>
                  <Styled.Label>{topService.name}</Styled.Label>
                  <Styled.LabelValue>
                    {topService.total} {currency}
                  </Styled.LabelValue>
                </Styled.FlexItem>
              ))}
            </Styled.TopServices>
          ),
        }}
      />

      {/* Section Performance des Employés */}
      <Tabulation
        title='Performance des employés'
        tabs={tabs}
        tabContents={{
          ventesDirectes: (
            <Styled.TopServices>
              {data?.employeesEarningsVentesDirectes.map((topEmploye) => (
                <Styled.FlexItem key={topEmploye.id}>
                  <Styled.Label>
                    {topEmploye.firstName} {topEmploye.lastName}
                  </Styled.Label>
                  <Styled.LabelValue>
                    {topEmploye.total} {currency}
                  </Styled.LabelValue>
                </Styled.FlexItem>
              ))}
            </Styled.TopServices>
          ),
          rendezVous: (
            <Styled.TopServices>
              {data?.employeesEarningsRdv.map((topEmploye) => (
                <Styled.FlexItem key={topEmploye.id}>
                  <Styled.Label>
                    {topEmploye.firstName} {topEmploye.lastName}
                  </Styled.Label>
                  <Styled.LabelValue>
                    {topEmploye.total}
                    {currency}
                  </Styled.LabelValue>
                </Styled.FlexItem>
              ))}
            </Styled.TopServices>
          ),
        }}
      />
    </>
  )
}
