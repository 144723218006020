import React from 'react'
import * as Styled from "./styles"
import { CACard } from '../Cards'
import { RepartitionCA } from '../RepartitionCA'
import { H1, H2, H3 } from '../styles'
import useSalonCurrency from 'hooks/useSalonCurrency'
import Wave from "../../../assets/images/wave.png"
import OrangeMoney from "../../../assets/images/orangeMoney.png"
import Especes from "../../../assets/images/especes.png"
import Banque from "../../../assets/images/banque.png"
import AmountDisplay from './amountDisplay'
import Autres from "../../../assets/images/autres.png"


export const Revenu = ({ data }) => {
  const currency = useSalonCurrency()
  return (
    <>
      <H1> Chiffre d'affaire</H1>
      <Styled.Container>
        <H2>Revenu généré</H2>
        <AmountDisplay amount={data?.total_ca} />
      </Styled.Container>
      <Styled.H1> Répartition du chiffre d'affaire</Styled.H1>
      <Styled.CAFlex>
        <CACard title='RDV payés' value={data?.appointments_ca} />
        <CACard title='Vente directe' value={data?.direct_sales_ca} />
      </Styled.CAFlex>
      <H1>Répartition du chiffre d'affaire par M.P.</H1>
      <RepartitionCA
        image={Wave}
        title='Wave'
        value={data?.ca_by_paiement_methods.ca_wave}
      />
      <RepartitionCA
        image={OrangeMoney}
        title='Orange Money'
        value={data?.ca_by_paiement_methods.ca_om}
      />
      <RepartitionCA
        image={Especes}
        title='Espèces'
        value={data?.ca_by_paiement_methods.ca_espece}
      />
      <RepartitionCA
        image={Banque}
        title='Carte bancaire'
        value={data?.ca_by_paiement_methods.ca_carte_bancaire}
      />
      <RepartitionCA
        image={Autres}
        title='Autres'
        value={data?.ca_by_paiement_methods.autres}
      />
    </>
  )
}
