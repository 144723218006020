import React from "react"
import * as Styled from "./styles"

const TabNavigation = ({ tabs, activeTab, onTabChange }) => {
  return (
    <Styled.Container>
      {tabs.map((tab) => (
        <Styled.StatusTitle
          key={tab.id}
          active={tab.id === activeTab}
          onClick={() => onTabChange(tab.id)} level={4}>
          {tab.title}
        </Styled.StatusTitle>
      ))}
    </Styled.Container>
  )
}

export default TabNavigation
