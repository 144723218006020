import request from "utils/request"
/**
 *
 * @param {string|number} salonId - salon id for dashboard data
 */
const getAll = ({ salonId, ...params }) => {
  if (!salonId) {
    throw new Error("No salonId was provided")
  }
  return request({
    url: `backoffice/salons/${salonId}/dashboard`,
    params: params.filters,
    method: "GET",
  })
}

const DashboardApi = {
  getAll,
}
export default DashboardApi
