import { DashboardFilter as FilterPeriod } from "components/Dashboard"
import *as Styled from "./styles"

export const Date = () => {
  return (
    <>
      <Styled.Container>
        <FilterPeriod />
      </Styled.Container>
    </>
  )
}
