import React from "react"
import * as Styled from "./styles"
import useSalonCurrency from "hooks/useSalonCurrency"

export const RepartitionCA = ({ image, title, value }) => {
  const currency = useSalonCurrency()
  return (
    <>
      <Styled.Container>
        <Styled.Flex>
          <Styled.Left>
            <Styled.Logo>
              <img
                src={image}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "100%",
                }}
                alt='Icone'
              />
            </Styled.Logo>
            <Styled.Title>{title}</Styled.Title>
          </Styled.Left>
          <Styled.Right>
            <Styled.Value>
              {value}&nbsp; {currency}
            </Styled.Value>
          </Styled.Right>
        </Styled.Flex>
      </Styled.Container>
    </>
  )
}
