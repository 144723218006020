
import styled from "styled-components"
import { elmaBreakpoints } from "styles/theme"

export const Container = styled.div`
  background: white;
  padding: 20px;
  @media screen and (max-width: ${elmaBreakpoints.md}px) {
    width: 700px;
  }
`

export const ChartContainer = styled.div`
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 1.7rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  overflow-x: auto;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

export const Title = styled.h2`
  font-size: 17px;
  color: #333;
  font-weight: 600;
`

export const YearSelect = styled.select`
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid #ddd;
  background: white;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    border-color: #65376A;
  }
`

export const ChartWrapper = styled.div`
  height: 280px;
  position: relative;
`

export const TooltipContainer = styled.div`
  background-color: #65376A;
  display: flex;
  align-items: center;
  color: white;
  height: 37px;
  padding: 0 10px;
  border-radius: 6px;
  transform: translateY(-80px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`

export const Valeur = styled.p`
  padding-top: 11px;
  font-size: 14px;
  font-weight: 400;
`

