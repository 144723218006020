import { useSalonQuery } from "actions/dashboard"
import { Col, Result } from "antd"
import { SkeletonCard } from "components/Dashboard/utils"
import { Context } from "contexts/Dashboard"
import { TAB_IDS, tabs } from "enums/constants"
import _ from "lodash"
import { useContext, useState } from "react"
import { Card } from "./Cards"
import Chart from "./Chart"
import { Date } from "./Date"
import { Revenu } from "./Revenu"
import * as Styled from "./styles"
import TabNavigation from "./TabNavigation"
import { TopServices } from "./TopServices"
import { ChartContainer } from "./Chart/styles"

const StatistiquesDashboard = ({ scrollAble }) => {
  const [state] = useContext(Context)
  const [activeTab, setActiveTab] = useState("dashboard")

  const { data: salonData, status } = useSalonQuery({
    period: state.dashboardPeriod,
    date: state.dashboardDate,
  })
  const isLoading = status === "loading" || status === "idle"
  const isError = status === "error"
  const content = () => {
    if (_.isEmpty(salonData?.appointments) && !isLoading && !isError) {
      return (
          <Result status='warning' title='Ne dispose pas encore des données' />
      )
    } else if (isError) {
      return (
        <Result
          status='500'
          title='500'
          subTitle={`Désolé, quelque chose s'est mal passé.`}
        />
      )
    } else {
      return (
        <Styled.Global scrollable={scrollAble}>
          {/* Navigation par onglets */}
          <Styled.Group>
            <TabNavigation
              tabs={tabs}
              activeTab={activeTab}
              onTabChange={setActiveTab}
            />
          </Styled.Group>

          <>
            <Date /> {/*Le filtre par Dates */}
          </>

          {/* Contenu dynamique basé sur l'onglet actif */}
          <Styled.Container>
            {activeTab === TAB_IDS.DASHBOARD && (
              <>
                <Styled.Flex>
                  <Col lg={15} sm={24} xs={24}>
                    <Styled.GroupLeft>
                      {isLoading ? (
                        <SkeletonCard />
                      ) : (
                        <Styled.KPIContainer>
                          <Card
                            title='Rendez-vous'
                            value={salonData?.appointments_count.current}
                            data={salonData?.appointments_count}
                          />
                          <Card
                            title='Ventes directes'
                            value={salonData?.direct_sales_count?.current}
                            data={salonData?.direct_sales_count}
                          />
                        </Styled.KPIContainer>
                      )}
                      {/* Chart Graphique */}
                      <ChartContainer>
                        <Chart data={salonData} />
                      </ChartContainer>
                      {/* Section des top services & Performances des employes*/}
                      {isLoading ? (
                        <SkeletonCard />
                      ) : (
                        <TopServices data={salonData} />
                      )}
                    </Styled.GroupLeft>
                  </Col>
                  <Col sm={24} xs={24} lg={9}>
                    <Styled.GroupRight>
                      {isLoading ? (
                        <SkeletonCard />
                      ) : (
                        <Revenu data={salonData} />
                      )}
                    </Styled.GroupRight>
                  </Col>
                </Styled.Flex>

                {/* Section KPI Clients*/}
                {/* <>
                  <Styled.CardSection>
                    {kpiClientData.map((data, index) => (
                      <CardClient
                        key={index}
                        title={data.title}
                        value={data.value}
                        icon={data.icon}
                      />
                    ))}
                  </Styled.CardSection>
                </> */}
              </>
            )}
            {activeTab === TAB_IDS.APPOINTMENTS && (
              <div>Gestion des Rendez-vous</div>
            )}
            {activeTab === TAB_IDS.CLIENTS && <div>Liste des Clients</div>}
            {activeTab === TAB_IDS.BUSINESS && (
              <div>Statistiques Financières</div>
            )}
            {activeTab === TAB_IDS.COLLABORATORS && (
              <div>Liste des Collaborateurs </div>
            )}
          </Styled.Container>
        </Styled.Global>
      )
    }
  }

  return content()
}

export default StatistiquesDashboard
