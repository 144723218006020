
import { FiUsers } from "react-icons/fi"
import { LuUserPlus } from "react-icons/lu"
import { SlBadge } from "react-icons/sl"

//============ Données des cartes KPI statistiques =========//
export const kpiData = [
  { title: "Rendez-vous", value: "13,0", variation: 10 },
  { title: "Ventes directes", value: "28,0", variation: 20 },
  { title: "Forfaits et packs", value: "00,0", variation: 5 },
]

//============ Données des cartes KPI clients =========//
export const kpiClientData = [
  { title: "Total clients", value: 500, icon: <FiUsers /> },
  { title: "Nouveaux clients", value: 50, icon: <LuUserPlus /> },
  { title: "Clients réguliers", value: 200, icon: <SlBadge /> },
]

//============ Données des Services =========//
export const topServices = [
  { label: "Massage Suédois", value: "30.000" },
  { label: "Massage aux pierres chaudes", value: "45.675" },
  { label: "Réflexologie", value: "87.987" },
]

//============ Performances des Employers =========//
export const performancesEmployes = [
  { label: "Emma", value: "30.000" },
  { label: "Liam", value: "45.675" },
  { label: "Olivia", value: "87.987" },
]

//============ Jours et Mois pour le chart=========//
const MONTHS = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Sept",
  "Oct",
  "Nov",
  "Déc",
]

const DAYS = [
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
  "Dimanche",
]


  //====== Déterminer le type de période
  const determinePeriodType = (revenueByPeriod) => {
    // Vérifier le nombre maximum de jours
    const maxDay = Math.max(...revenueByPeriod.map((item) => item.day || 0))
    if (maxDay > 8) return "month"
    if (maxDay > 0) return "week"
    return "year"
  }

  //====== Transformer les données pour le graphique
   export const transformChartData = (revenueByPeriod) => {
    const periodType = determinePeriodType(revenueByPeriod)

    let fullPeriodData
    switch (periodType) {
      case "week":
        // Pour une semaine, utiliser les jours de la semaine
        fullPeriodData = Array.from({ length: 7 }, (_, index) => ({
          label: DAYS[index],
          day: index + 1,
          value: 0,
        }))
        break
      case "month":
        // Pour un mois, utiliser les jours de 1 à 31
        fullPeriodData = Array.from({ length: 31 }, (_, index) => ({
          label: `${index + 1}`,
          day: index + 1,
          value: 0,
        }))
        break
      case "year":
        // Pour une année, utiliser les mois
        fullPeriodData = Array.from({ length: 12 }, (_, index) => ({
          label: MONTHS[index],
          month: index + 1,
          value: 0,
        }))
        break
    }

    // Fusionner avec les données réelles
    revenueByPeriod.forEach((item) => {
      const matchIndex = fullPeriodData.findIndex(
        (data) =>
          (item.day !== undefined && data.day === item.day) ||
          (item.month !== undefined && data.month === item.month),
      )

      if (matchIndex !== -1) {
        fullPeriodData[matchIndex].value = parseFloat(item.total)
      }
    })

    return fullPeriodData
  }


