import React, { useRef } from "react"
import * as Styled from "../../styles/index"
import { Divider, Space } from "antd"
import { Download } from "react-feather"
import PaiementServices from "../PaimentServices/PaiementServices"
import ButtonPaiement from "../ButtonPaiement"
import { useNavigate } from "react-router"
import RecuHeader from "./RecuHeader"
import PdfPrint from "components/Pdf/PdfPrint"
import { useReactToPrint } from "react-to-print"
// import PrintRecuPaiement from './PrintRecuPaiement'
import { useFormikContext } from "formik"
import RecuToPrint from "./RecuToPrint"

// ! ce composant esr reutilisable. Les donnes proviennent provienent de son provider le plus proche
const RecuPaiement = () => {
  const navigate = useNavigate()
  // ! {values} is from RecuPaiementProvider
  const { values } = useFormikContext()

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  return (
    <>
      <div style={{ display: "none" }}>
        <PdfPrint ref={componentRef}>
          {/* <PrintRecuPaiement /> */}
          <RecuToPrint />
        </PdfPrint>
      </div>
      <Styled.RecuContainerPane>
        <Styled.RecuContainer>
          <RecuHeader handlePrint={handlePrint} />
          <Divider style={{ borderWidth: "2px" }} variant='dashed' dashed />
          {values.currentFacture.etat !== "paye" && (
            <Styled.Ruban>Annulée</Styled.Ruban>
          )}
          <PaiementServices
            mode_paiement={values.currentFacture.mode_paiement}
            numero_facture={values.currentFacture.numero_facture}
            services={values.currentFacture.services}
            paymentDate={values.currentFacture.date_facture}
            client={values.currentFacture.client}
            montant={values.currentFacture.montant}
          />
          {values.currentFacture.etat === "paye" && (
            <ButtonPaiement onClick={handlePrint}>
              <Space>
                <Download /> Obtenir un reçu PDF
              </Space>
            </ButtonPaiement>
          )}

          <Styled.LinkText onClick={() => navigate(values.navigateBack)}>
            {values.navigateText}
          </Styled.LinkText>
        </Styled.RecuContainer>
      </Styled.RecuContainerPane>
    </>
  )
}

export default RecuPaiement
