import React, { useState } from "react"
import * as Styled from "../styles"

export const Tabulation = ({ title, tabs, tabContents }) => {
  // État pour suivre l'onglet actif
  const [activeTab, setActiveTab] = useState(tabs[0].key)

  return (
    <Styled.Section>
      <Styled.TabGroup>
        <Styled.H1>{title}</Styled.H1>
        <Styled.TabContainer>
          {tabs.map((tab) => (
            <Styled.Tab
              key={tab.key}
              isActive={activeTab === tab.key}
              onClick={() => setActiveTab(tab.key)}>
              {tab.label}
            </Styled.Tab>
          ))}
        </Styled.TabContainer>
      </Styled.TabGroup>
      <Styled.ContentTransition key={activeTab}>
        {tabContents[activeTab]}
      </Styled.ContentTransition>
    </Styled.Section>
  )
}
