import { Button, Modal } from "antd"
import React from "react"
import Input from "components/Input"
import * as Styled from "../styles"

const MotifModal = ({
  isModalOpen,
  motif,
  handleMotif,
  handleCancel,
  handleOk,
  isLoading,
}) => {
  return (
    <Modal
      title='Motif'
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}>
      <Input
        required
        value={motif}
        onChange={(e) => {
          handleMotif(e.target.value)
        }}
        placeholder="Motif d'annulation"
      />
      <Styled.FormFooter>
        <Button onClick={handleCancel} className='elma-btn-custom-cancel'>
          Annuler
        </Button>
        <Button
          onClick={handleOk}
          type='primary'
          className='elma-btn-custom'
          loading={isLoading}>
          Envoyer
        </Button>
      </Styled.FormFooter>
    </Modal>
  )
}

export default MotifModal
