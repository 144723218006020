import styled from "styled-components"
import { elmaBreakpoints } from "styles/theme"

export const Global = styled.div`
  margin: 20px auto 0;
`
export const Group = styled.div`
  display: flex;
  justify-content: space-between;
`
export const Container = styled.div`
  width: 98%;
  margin: 20px auto 0;
`
export const Flex = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 1.7rem;
  @media screen and (min-width: ${elmaBreakpoints.lg}px) {
    flex-direction: row;
  }
`

export const GroupLeft = styled.div`
  /* width: 63%; */
  display: flex;
  flex-direction: column;
`
export const GroupRight = styled.div`
  /* width: 37%; */
  padding: 1.7rem;
  background: #ffffff;
  border: 1px solid #ccc;
  border-radius: 10px;
`

export const KPIContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
export const Section = styled.div`
  width: 100%;
  padding: 1rem 1.7rem;
  margin-top: 25px;
  background: #ffffff;
  border: 1px solid #ccc;
  border-radius: 10px;
`

export const H1 = styled.h1`
  font-size: 17px;
  color: #252c32;
  font-weight: 600;
`
export const H2 = styled.p`
  font-size: 21px;
  color: white;
  margin-top: 0.8rem;
`

export const H3 = styled.p`
  color: white;
  font-size: 40px;
`
export const TopServices = styled.div``
export const FlexItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
`
export const Label = styled.span`
  color: #09090b;
  font-weight: 400;
  font-size: 16px;
`
export const LabelValue = styled.span`
  color: #09090b;
  font-weight: 600;
  font-size: 17px;
`

//========= Section des cartes clients =========//

export const CardSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`
//=========  Tabulations des Top services ============//

export const TabContainer = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 1rem;
  width: 55%;
  gap: 7px;
  @media screen and (max-width: ${elmaBreakpoints.xl}px) {
    width: 100%;
  }
`
export const TabGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
    @media screen and (min-width: ${elmaBreakpoints.xl}px) {
    flex-direction: row;
  }

`

export const Tab = styled.div`
  flex: 1;
  cursor: pointer;
  padding: 0.2rem 1rem;
  border-radius: 7px;
  font-size: 16px;
  color: ${({ isActive }) => (isActive ? "#ffffff" : "#252c32")};
  background: ${({ isActive }) => (isActive ? "#65376A" : "#f4f4f4")};
  border: none;
  cursor: pointer;
  text-align: center;
  transition:
    color 0.3s ease-in-out,
    background 0.6s ease-in-out;

  &:hover {
    background: ${({ isActive }) => (isActive ? "#65376A" : "#e8e4e8")};
  }
`

export const ContentTransition = styled.div`
  animation: fadeIn 0.5s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-8px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

