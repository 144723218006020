import React from "react"
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts"
import * as Styled from "./styles"
import { transformChartData } from "../Utils"

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Styled.TooltipContainer>
        <Styled.Valeur>{`${label} : ${payload[0].value} XOF`}</Styled.Valeur>
      </Styled.TooltipContainer>
    )
  }
  return null
}

const Chart = ({ data }) => {

  // Vérifier si des données sont disponibles
  const chartData = data?.revenueByPeriod
    ? transformChartData(data.revenueByPeriod)
    : []

  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.Title>Chiffre d'affaire</Styled.Title>
      </Styled.Header>

      <Styled.ChartWrapper>
        <ResponsiveContainer width='100%' height='100%'>
          <LineChart
            data={chartData}
            margin={{ top: -10, right: 10, }}>
            <CartesianGrid
              strokeDasharray='0'
              vertical={false}
              stroke='#E5E7EB'
            />
            <XAxis
              dataKey='label'
              axisLine={false}
              tickLine={false}
              dy={10}
              tick={{ fill: "#6B7280" }}
            />
            <YAxis
              padding={{ top: 20 }}
              axisLine={false}
              tickLine={false}
              tickFormatter={(value) => `${value / 1000}K`}
              dx={-10}
              interval={0}
              tick={{ fill: "#6B7280" }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type='monotone'
              dataKey='value'
              stroke='#65376A'
              strokeWidth={2}
              dot={false}
              activeDot={{
                r: 5,
                stroke: "#65376A",
                fill: "white",
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Styled.ChartWrapper>
    </Styled.Container>
  )
}

export default Chart
