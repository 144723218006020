import useSalonCurrency from "hooks/useSalonCurrency"
import React from "react"
import styled from "styled-components"

const H4 = styled.p`
  color: white;
  font-size: ${({ fontSize }) => fontSize || "40px"}; // Taille dynamique
`

const AmountDisplay = ({ amount }) => {
  const currency = useSalonCurrency()
    const formattedAmount = new Intl.NumberFormat("fr-FR").format(amount)

  // Déterminer la taille du texte en fonction de l'argent
  let fontSize = "40px"

  if (amount >= 1_000_000_000) {
    fontSize = "25px" 
  } else if (amount >= 1_000_000) {
    fontSize = "30px"
  } else if (amount >= 100_000) {
    fontSize = "35px"
  }

  return (
    <H4 fontSize={fontSize}>
      {formattedAmount}&nbsp;{currency}
    </H4>
  )
}

export default AmountDisplay
