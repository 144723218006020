import styled from "styled-components"
import BackgroundImage from "../../../../assets/images/logo.png"

export const Container = styled.div`
  width: 100%;
  line-height: 2rem;
  border-radius: 10px;
  padding: 2.2rem 1.5rem;
  background-color: #65376a;
  background-image: linear-gradient(
      rgba(101, 55, 106, 0.95),
      rgba(101, 55, 106, 0.95)
    ),
    url(${BackgroundImage});
  background-position: right;
  background-repeat: no-repeat;
  margin-bottom: 1.5rem;
`
export const CAFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  margin-bottom: 20px;
`
export const H1 = styled.h1`
  font-size: 17px;
  color: #252c32;
  font-weight: 600;
`