import React from "react";
import * as Styled from "./styles";
import useSalonCurrency from "hooks/useSalonCurrency";
import { getPercentegeDiff } from "utils";

export const Card = ({ title, value, data }) => {
  return (
    <Styled.CardContainer>
      <Styled.Flex>
        <Styled.Title>{title}</Styled.Title>
        <Styled.Variation>
          {`${data?.current > data?.prev ? "+" : "-"}${
            getPercentegeDiff(data?.current, data?.prev) || "N/A"
          }% `}
        </Styled.Variation>
      </Styled.Flex>
      <Styled.Value>{value}</Styled.Value>
    </Styled.CardContainer>
  )
};

export const CardClient = ({ title, value, icon }) => {
    const currency = useSalonCurrency()
  return (
    <Styled.KPICard>
      <Styled.ClientCard>
        <Styled.Title>{title}</Styled.Title>
        <Styled.IconWrapper>{icon}</Styled.IconWrapper>
      </Styled.ClientCard>
      <Styled.Value>
        {value}&nbsp;{currency}
      </Styled.Value>
    </Styled.KPICard>
  )
}

export const CACard = ({ title, value }) => {
    const currency = useSalonCurrency()
  return (
    <Styled.CAFlex>
      <Styled.CATitle>{title}</Styled.CATitle>
      <Styled.CAValue>
        {value}&nbsp;{currency}
      </Styled.CAValue>
    </Styled.CAFlex>
  )
}

