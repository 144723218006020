import useSalonId from "hooks/useSalonId"
import { useQuery } from "react-query"
import DashboardAPI from "./api"

export const useSalonQuery = (filters) => {
  const salonId = useSalonId()
  return useQuery(
    ["dashboard", salonId, filters],
    () => DashboardAPI.getAll({ salonId, filters }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onError: (err) => {
        console.log(err)
      },
    }
  )
}
