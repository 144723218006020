import styled from "styled-components"
import { elmaBreakpoints } from "styles/theme"

// =============== Style Carte KPI Statistique ===============//

export const CardContainer = styled.div`
  background: #ffffff;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 1.4rem;
  border-radius: 10px;
  width: 48.2%;
`

export const Flex = styled.div`
  width: 100%;
  justify-content: space-between;
  background: #fff;
  display: flex;
  align-items: center;
`

export const Title = styled.div`
  font-size: 13px;
  color: #6b7280;
`

export const Value = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #333;
`

export const Variation = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #22c55e;
`

// =============== Style Carte Chiffre d'affaire ===============//

export const CAFlex = styled.div`
  background: #f7f7f8;
  border-radius: 10px;
  min-width: 48%;
  padding: 1rem;
  @media screen and (max-width: 1320px) {
    width: 100%;
  }
  @media screen and (max-width: 992px) {
    width: 48%;
  }
  @media screen and (max-width: 467px) {
    width: 100%;
  }
`

export const CATitle = styled.div`
  font-size: 13px;
  color: #6b7280;
`

export const CAValue = styled.div`
  font-size: 18px;
  font-weight: 550;
  color: #333;
`
//========= Section des cartes clients ===============//

export const KPICard = styled.div`
  background: #ffffff;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 1.4rem;
  width: 32%;
  border-radius: 10px;
`

export const ClientCard = styled.div`
  width: 100%;
  justify-content: space-between;
  background: #fff;
  display: flex;
  align-items: center;
  gap: 2rem;
`

export const IconWrapper = styled.div`
  font-size: 24px;
  color: #a27ec6;
`